// // Wait for page to load
document.addEventListener("DOMContentLoaded", function () {
  // Get the current time and date
  let currentTime = new Date();

  // Check if popup has been shown to the user already
if (!sessionStorage.getItem("popUpShown2")) {
  // Check if the current day is Saturday or Sunday
  if (currentTime.getDay() == 0 || currentTime.getDay() == 6) {
    // Check if the current time is outside of 6am to 6pm
    if (currentTime.getHours() < 6 || currentTime.getHours() >= 18) {
      // If it is, display a popup to the user
      //   console.log("It's currently outside of 6am to 6pm on a weekend.");
      toggleModal();
      // sessionStorage.setItem("popUpShown2", true);
    }
  }
  // Check if the current day is Monday to Friday
  else if (currentTime.getDay() >= 1 && currentTime.getDay() <= 5) {
    // Check if the current time is outside of 6am to 6pm
    if (currentTime.getHours() < 6 || currentTime.getHours() >= 18) {
      // If it is, display a popup to the user
      //   console.log("It's currently outside of 6am to 6pm on a weekday.");
      toggleModal();
      // sessionStorage.setItem("popUpShown2", true);
    }
  }
}


  // Function to toggle open and close the modal
  function toggleModal() {
    document.getElementById("popup-wrapper-2").classList.toggle("tw-hidden");
    document.getElementById("popup-wrapper-2").classList.toggle("tw-flex");
    document.body.classList.toggle("tw-overflow-hidden");
  }

  // When the user clicks the close button or anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == document.getElementById("closePopup2")) {
      toggleModal();
    }
  };
});
